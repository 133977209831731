<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <!-- Filters -->
      <revenue-filters
        :branch-filter.sync="branchFilter"
        :branch-options="branchOptions"
        :year-filter.sync="yearFilter"
        :month-filter.sync="monthFiter"
        :month-options="monthOptions"
        :subject-filter.sync="subjectFilter"
        :subject-options="subjectOptions"
      />

      <!-- Table Container Card -->
      <b-card
        :title="$t('Sale') + $t('Revenues')"
        class="mb-0"
      >

        <b-table
          ref="refSaleRevenueTable"
          responsive
          :items="fetchSaleRevenues"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
        />

      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BTable, VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import RevenueFilters from './RevenueFilters.vue'
import useRevenue from './useRevenue'
import storeModule from '../../common/storeModule'
import revenueStoreModule from './revenueStoreModule'

export default {
  components: {
    RevenueFilters,
    BOverlay,
    BCard,
    BTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      branchOptions: [],
      monthOptions: [
        { label: this.$t('January'), value: 1 },
        { label: this.$t('February'), value: 2 },
        { label: this.$t('March'), value: 3 },
        { label: this.$t('April'), value: 4 },
        { label: this.$t('May'), value: 5 },
        { label: this.$t('June'), value: 6 },
        { label: this.$t('July'), value: 7 },
        { label: this.$t('August'), value: 8 },
        { label: this.$t('September'), value: 9 },
        { label: this.$t('October'), value: 10 },
        { label: this.$t('November'), value: 11 },
        { label: this.$t('December'), value: 12 },
      ],
      subjectOptions: [
        // sale_region,branch,item,staff,client
        { label: this.$t('Branch'), value: 'branch' },
        { label: this.$t('Client'), value: 'client' },
        { label: this.$t('Staff'), value: 'staff' },
        { label: this.$t('Sale Region'), value: 'sale_region' },
      ],
    }
  },
  // watch: {
  //   subjectFilter: () => {
  //     this.loadTableColumns()
  //   },
  // },
  setup() {
    const STORE_MODULE_NAME = 'revenue'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, revenueStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchSaleRevenues,
      refSaleRevenueTable,
      refetchData,
      tableColumns,
      // Filters
      branchFilter,
      yearFilter,
      monthFiter,
      subjectFilter,

    } = useRevenue()

    return {

      fetchSaleRevenues,
      refSaleRevenueTable,
      refetchData,
      tableColumns,
      // Extra Filters
      branchFilter,
      yearFilter,
      monthFiter,
      subjectFilter,

    }
  },
  mounted() {
    this.loadBranches()
  },
  methods: {
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
