import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueI18n from '@/libs/i18n/index'

export default function useSaleList() {
  // Use toast
  const toast = useToast()
  const i18n = VueI18n

  const refSaleRevenueTable = ref(null)

  const branchFilter = ref(null)
  const yearFilter = ref(new Date().getFullYear())
  const monthFiter = ref(null)
  const subjectFilter = ref('branch')

  const refetchData = () => {
    refSaleRevenueTable.value.refresh()
  }

  const tableColumns = ref(null)
  let subject = i18n.t('Branch')
  tableColumns.value = [
    {
      key: subjectFilter.value,
      label: subject,
      sortable: true,
      tdClass: 'noPadding',
      thClass: 'noPadding',
    },
    {
      key: 'January', label: 'Jan', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'February', label: 'Feb', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'March', label: 'Mar', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'April', label: 'Apr', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'May', label: 'May', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'June', label: 'Jun', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'July', label: 'Jul', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'August', label: 'Aug', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'September', label: 'Sept', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'October', label: 'Oct', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'November', label: 'Nov', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
    {
      key: 'December', label: 'Dec', sortable: true, tdClass: 'noPadding', thClass: 'noPadding',
    },
  ]

  const reloadTableColumns = () => {
    i18n.locale = localStorage.locale

    switch (subjectFilter.value) {
      case 'brand': subject = i18n.t('Branch'); break
      case 'item': subject = i18n.t('Item'); break
      case 'staff': subject = i18n.t('Staff'); break
      case 'sale_region': subject = i18n.t('Sale Region'); break
      default: subject = i18n.t('Branch')
    }

    tableColumns.value[0].key = subjectFilter.value
    tableColumns.value[0].label = subject

    // tableColumns.value = [
    //   { key: subjectFilter.value, label: subject, sortable: true },
    //   { key: 'January', label: 'January', sortable: true },
    //   { key: 'February', label: 'February', sortable: true },
    //   { key: 'March', label: 'March', sortable: true },
    //   { key: 'April', label: 'April', sortable: true },
    //   { key: 'May', label: 'May', sortable: true },
    //   { key: 'June', label: 'June', sortable: true },
    //   { key: 'July', label: 'July', sortable: true },
    //   { key: 'August', label: 'August', sortable: true },
    //   { key: 'September', label: 'September', sortable: true },
    //   { key: 'October', label: 'October', sortable: true },
    //   { key: 'November', label: 'November', sortable: true },
    //   { key: 'December', label: 'December', sortable: true },
    // ]
  }

  watch([branchFilter, yearFilter, monthFiter, subjectFilter], () => {
    refetchData()
    reloadTableColumns()
  })

  const fetchSaleRevenues = async (ctx, callback) => {
    store
      .dispatch('revenue/fetchAll', {
        branch_id: branchFilter.value,
        year: yearFilter.value,
        month: monthFiter.value,
        subject: subjectFilter.value,
      })
      .then(response => {
        callback(response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting report',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSaleRevenues,
    refSaleRevenueTable,
    refetchData,
    tableColumns,

    // Filters
    branchFilter,
    yearFilter,
    monthFiter,
    subjectFilter,
  }
}
